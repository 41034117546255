<template>
  <div class="no-records-wrapper">
    <p v-if="title" class="get-started">{{ title }}</p>
    <p v-if="text" v-html="text" class="get-started-text"></p>

    <!-- Create project button -->
    <DButton v-if="buttonText"
             @click.native="$emit('create')"
             :text="buttonText"
             :icon="buttonIcon"
             type="button"
             class="button-main button-create"/>
  </div>
</template>

<script>
import DButton from "../elements/DButton";

export default {
  name: "NoRecordsYet",
  components: {
    DButton
  },
  props: {
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    buttonText: {
      type: String,
      required: false
    },
    buttonIcon: {
      type: String,
      required: false
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";
@import "../../assets/css/layout.shared";

.no-records-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  max-width: rem(561);
}

.get-started {
  @extend .mont-bold;
  color: var(--black-light);
  font-size: rem(24);
  line-height: rem(29);
  margin-bottom: rem(32);
  margin-top: rem(60);

  &-text {
    color: var(--gray-light-03);
    font-size: rem(20);
    line-height: rem(30);
    margin-bottom: rem(48);
  }
}

.button-create {
  width: rem(190);
}
</style>
